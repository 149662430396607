import React from "react";
import {
    BrowserRouter,
    createBrowserRouter,
    RouterProvider,
    Routes,
    Route
} from "react-router-dom";
import App from "./App";

export default function Router() {
    return (<BrowserRouter>
    <Routes>
        <Route element={<App />} index/>
        
    </Routes>
    </BrowserRouter>)
} 