import React from 'react';
import 'antd/dist/reset.css';
// import './App.css';
import { Breadcrumb, Layout, Menu, theme, Card, Button, Form, Alert, notification } from 'antd';
import { DEFAULT_THEME, ThemeProvider, SixsenseColor, DesignSystemColor, DesignSystemThemeColor } from "@sixsense/core/style";
import {css} from "@emotion/css";
import WebTag from "./components/WebTag";
import ReactPlayer from 'react-player';
function App() {
  return (
    <>
    <ThemeProvider theme={DEFAULT_THEME}>
      <Layout>
        <Layout.Header className={css`
          background-color: ${SixsenseColor.Blue.DARK_5} !important;
        `}>
          <h1 className={css`color: white;`}>6sense WebTag Proxy Demo</h1>
        </Layout.Header>
      <WebTag />
      <Layout.Content>
        <Card title="Link Demo">
          <a href='https://6sense.com' target="_blank" onClick={(ev)=>{
            ev.preventDefault();
            notification.info({
              message: "Link Suppressed for Demo",
              description: "The new tab was supressed for the demo, but the click event will still register"
            })
            }}>
            <Button>Open 6sense.com</Button>
          </a>
        </Card>
        <Card title="Form Demo">
          <form action='#' id="test-form" onSubmit={(ev)=>{
            ev.preventDefault();
            notification.info({
              message: "Form Suppressed for Demo",
              description: "The form submit was supressed for the demo, but the form event will still register"
            })
          }}>
            <Form.Item label="Company Name">
              <input name="companyName"></input>
            </Form.Item>
            <Form.Item label="Email">
              <input type="email" name="email"></input>
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' name="submit">Submit</Button>
            </Form.Item>
          </form>
        </Card>
        <Card title="Video">
          <ReactPlayer id="test-video" url="https://embed-fastly.wistia.com/deliveries/45cf59cda0bec8f658c34783b7dc4a0666b6fdba/file.mp4" controls />
            
        </Card>
      </Layout.Content>
      </Layout>
    </ThemeProvider>
    </>
  );
}

export default App;
